// import { Button } from "antd";
import { message } from "antd";
import {
    FC, useEffect, useState
} from "react";
import { createUseStyles } from "react-jss";
import VideoRecorder from 'react-video-recorder';
import styled from 'styled-components';
import { getThumbnails } from "video-metadata-thumbnails";

import Button from 'react-video-recorder/lib/defaults/button';
import Countdown from 'react-video-recorder/lib/defaults/countdown';
import RecordButton from 'react-video-recorder/lib/defaults/record-button';
import StopButton from 'react-video-recorder/lib/defaults/stop-button';
import Timer from 'react-video-recorder/lib/defaults/timer';
require("dotenv").config();

const fail = (error: string) => {
    throw error;
};

const ActionsWrapper = styled.div`
position: absolute;
left: 0;
right: 0;
top: 50%;
display: flex;
align-items: center;
justify-content: center;
`;



const LAMBDA_URL =
    process.env.REACT_APP_PRESIGNED_URL_LAMBDA_URL ||
    fail("Aborting: LAMBDA_URL environmental variable is missing.");

export const CreateVideo: FC = () => {
    const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [hasUploaded, setHasUploaded] = useState(false);
    //   //State: IDLE, RECORDING
    //   const [state, setState] = useState("IDLE");

    const useStyles = createUseStyles({
        disableButton: {
            backgroundColor: "rgb(229, 229, 229) !important",
            cursor: "not-allowed",
            pointerEvents: "none"
        },

    });
    const classes = useStyles();

    const uploadVideo = async (videoBlob) => {
        if (isUploading) {
            return;
        }
        setIsUploading(true);
        if (!videoBlob) {
            message.error("No Video Found"); // todo show error instead

            return;
        }

        const thumbnails = await getThumbnails(videoBlob, {
            quality: 0.5,
            start: 0,
        });
        const thumbnailBlob = thumbnails[0].blob as Blob;

        //set a default platform, userId, and videoId in case someone tests this website without running it in an iframe
        //or if no platform/userId/videoId info is correctly included in the iframe url
        let platform = "engage";
        let userId = "30";
        let videoId = "88888888";
        if (window.location !== window.parent.location) {
            // The page is in an iframe
            console.log("in iframe");
            try {
                //if videoid is '0', it means the user is recording the video on the conversation page and we need to generate a videoId in the presignedUrl lambda
                [platform, userId, videoId] = window
                    .atob(document.location.href.split("?")[1])
                    .split("/");
            } catch (error) {
                console.log("Error in window location", error);
            }
        }
        console.log(
            "platform: ",
            platform,
            " userId: ",
            userId,
            " videoId: ",
            videoId
        );

        //userId is of type string when we fetch it from url, convert it to int
        const requestData = {
            userId: parseInt(userId),
            platform,
            filetype: "video/mp4",
            videoId: videoId,
        };
        const response = await fetch(LAMBDA_URL, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        });
        const data = await response.json();
        const presignedUrl = data['presignedUrl'];
        const thumbnailPresignedUrl = data['thumbnailPresignedUrl'];
        if (videoId === "0") {
            videoId = data['videoId'];
        }
        console.log("presignedUrl: ", presignedUrl);
        console.log("thumbnailPresignedUrl: ", thumbnailPresignedUrl);
        console.log("videoId: ", videoId);
        const url = "https://master.d3sdu3vsjoaikd.amplifyapp.com/watch/" + videoId;
        const thumbnailUrl = `https://sms-video-thumbnails.s3-us-west-2.amazonaws.com/${videoId}-thumbnail.jpg`;
        if (response.ok) {
            const file = new File([videoBlob], videoId);
            try {
                await fetch(thumbnailPresignedUrl, {
                    method: "PUT",
                    mode: "cors",
                    headers: {
                        "x-amz-acl": "public-read",
                        "Content-Type": "image/jpeg",
                    },
                    body: thumbnailBlob,
                });
                await fetch(presignedUrl, {
                    method: "PUT",
                    mode: "cors",
                    headers: {
                        "x-amz-acl": "public-read",
                        "Content-Type": "video/mp4",
                    },
                    body: file,
                });

                message.success("Successfully uploaded"); // todo show error instead

                window.parent.postMessage(
                    {
                        eventId: "videoUrl",
                        url: url,
                        thumbnailUrl: thumbnailUrl,
                        videoId: videoId
                    },
                    "*"
                );
            } catch (error) {
                message.error("Oops, something went wrong "); // todo show error instead

            }
        } else {
            console.error(data.errors);
        }
        setIsUploading(false);
        setHasUploaded(true);

    };

    useEffect(() => {
        if (videoBlob) {
            uploadVideo(videoBlob);
        }
    }, [videoBlob]);

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <div style={{ height: "100vh", width: "100vw" }}>
                <VideoRecorder
                    onRecordingComplete={VB => {
                        setVideoBlob(VB);
                    }}
                    isOnInitially
                    showReplayControls
                    replayVideoAutoplayAndLoopOff
                    renderActions={({
                        t,
                        isVideoInputSupported,
                        isInlineRecordingSupported,
                        thereWasAnError,
                        isRecording,
                        isCameraOn,
                        streamIsReady,
                        isConnecting,
                        isRunningCountdown,
                        isReplayingVideo,
                        countdownTime,
                        timeLimit,
                        showReplayControls,
                        replayVideoAutoplayAndLoopOff,
                        useVideoInput,

                        onTurnOnCamera,
                        onTurnOffCamera,
                        onOpenVideoInput,
                        onStartRecording,
                        onStopRecording,
                        onPauseRecording,
                        onResumeRecording,
                        onStopReplaying,
                        onConfirm
                    }) => {
                        const renderContent = () => {
                            const shouldUseVideoInput =
                                !isInlineRecordingSupported && isVideoInputSupported;

                            if (
                                (!isInlineRecordingSupported && !isVideoInputSupported) ||
                                thereWasAnError ||
                                isConnecting ||
                                isRunningCountdown
                            ) {
                                return null;
                            }

                            if (isReplayingVideo) {
                                return (
                                    <div>
                                        <Button
                                            type='button'
                                            onClick={() => { onStopReplaying(); setHasUploaded(false); }}
                                            style={{ marginRight: "16px" }}
                                            data-qa='start-replaying'
                                        >
                                            {t('Use another video')}
                                        </Button>
                                        {isUploading && <Button
                                            type='button'
                                            onClick={() => { uploadVideo(videoBlob); }}
                                            data-qa='start-replaying'
                                            className={isUploading ? classes.disableButton : ""}
                                            disabled={isUploading}
                                        >
                                            {t('Uploading...')}
                                        </Button>}
                                    </div>
                                );
                            }

                            if (isRecording) {
                                return (
                                    <StopButton
                                        type='button'
                                        onClick={onStopRecording}
                                        data-qa='stop-recording'
                                    />
                                );
                            }

                            if (isCameraOn && streamIsReady) {
                                return (
                                    <RecordButton
                                        t={t}
                                        type='button'
                                        onClick={onStartRecording}
                                        data-qa='start-recording'
                                    />
                                );
                            }

                            if (useVideoInput) {
                                return (
                                    <Button type='button' onClick={onOpenVideoInput} data-qa='open-input'>
                                        {t('Upload a video')}
                                    </Button>
                                );
                            }

                            return shouldUseVideoInput ? (
                                <Button type='button' onClick={onOpenVideoInput} data-qa='open-input'>
                                    {t('Record a video')}
                                </Button>
                            ) : (
                                <Button type='button' onClick={onTurnOnCamera} data-qa='turn-on-camera'>
                                    {t('Turn my camera ON')}
                                </Button>
                            );
                        };

                        return (
                            <div>
                                {isRecording && <Timer timeLimit={timeLimit} />}
                                {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
                                <ActionsWrapper>{renderContent()}</ActionsWrapper>
                            </div>
                        );
                    }}
                >
                </VideoRecorder>
            </div>
        </div>
    );
};

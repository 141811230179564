import React from 'react';
import { CreateVideo } from './components/CreateVideo';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Sandbox } from "./components/Sandbox";
import 'antd/dist/antd.css';
const App: React.FC = () => {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={CreateVideo} />
                    <Route path="/watch/:videoId" exact component={Sandbox} />
                </Switch>
            </BrowserRouter>
        </>
    );
};

export default App;

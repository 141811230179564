import { FC } from 'react';
import { Button } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';

interface VideoTextProps {
    message: string;
    phoneNumber: string;
    expandVideo: boolean;
}

export const VideoText: FC<VideoTextProps> = (props: VideoTextProps) => {
    const { message, phoneNumber } = props;

    const replyLink = "sms://" + phoneNumber;
    return (
        <div>
            <div style={{ marginLeft: "4px", paddingLeft: "3px" }}>
                <p style={{ borderLeft: "3px solid #40a9ff", paddingTop: "3px", paddingLeft: "5px", paddingRight: "5px", fontSize: "x-small", wordWrap: "break-word" }}>{message}</p>
                <div style={{ paddingBottom: "9px" }}>
                    <Button href={replyLink}
                        type="primary"
                    >Reply</Button>{' '}
                </div>
            </div>
        </div>
    );
};
import { faExclamationTriangle, faPlay, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AWS from "aws-sdk";
import * as dynamoose from "dynamoose";
import { FC, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import ReactPlayer from 'react-player/file';
import { RouteComponentProps } from "react-router";
import { VideoText } from "./VideoText";
import './index.css';

require('dotenv').config();

const fail = (error: string) => {
    throw error;
};

const SQS_URL = process.env.REACT_APP_SQS_URL_FOR_UPDATING_VIDEO_TASK_STATUS_IN_FIRESTORE || fail("Aborting: SQS_URL_FOR_UPDATING_VIDEO_TASK_STATUS_IN_FIRESTORE environmental variable is missing.");
const AMAZON_ACCESS_KEY_ID = process.env.REACT_APP_AMAZON_ACCESS_KEY_ID || fail("Aborting: AMAZON_ACCESS_KEY_ID environmental variable is missing");
const AMAZON_ACCESS_KEY_SECRET = process.env.REACT_APP_AMAZON_ACCESS_KEY_SECRET || fail("Aborting: AMAZON_ACCESS_KEY_SECRET environmental variable is missing");
const AMAZON_REGION = process.env.REACT_APP_AMAZON_REGION || fail("Aborting: AMAZON_REGION environmental variable is missing.");
const SMS_URL = process.env.REACT_APP_SMS_URL || fail("Aborting: SMS_URL environmental variable is missing.");
const AWS_CONFIG = {
    accessKeyId: AMAZON_ACCESS_KEY_ID,
    secretAccessKey: AMAZON_ACCESS_KEY_SECRET,
    region: AMAZON_REGION
};
dynamoose.aws.sdk.config.update(AWS_CONFIG);
AWS.config.update(AWS_CONFIG);
const videoTextingSchema = new dynamoose.Schema(
    {
        videoId: {
            type: String,
            hashKey: true
        },
        platform: String,
        accountId: Number,
        videoWidth: Number,
        videoHeight: Number,
        createAt: Number,
        updateAt: Number
    },
    {
        saveUnknown: true
    }
);

const VideoModel = dynamoose.model("VideoTexting", videoTextingSchema);
interface Props extends RouteComponentProps<{ videoId: string; }> { }
const rowHeight = window.innerHeight / 2;
let rowWidth = (rowHeight) * 1.68;
const videoTextWidth = rowHeight * .68;
export const Sandbox: FC<Props> = ({ match }) => {
    const videoId = match.params.videoId;
    const getLinkToVideo = (videoId: string) => {
        const videoSource = `${SMS_URL}/${videoId}/${videoId}.m3u8`;
        return videoSource;
    };
    const useStyles = createUseStyles({
        displayIcon: {
            position: "absolute",
            marginLeft: "2vh",
            bottom: 0,
            "margin-bottom": "10px"
        },
        removeIcon: {
            display: "none"
        },
        column: {
            justifyContent: "flex-start",
            display: "flex",
            paddingTop: '80px',
            alignItems: "center",
            flexDirection: "column",
            minHeight: "100vh",
            borderRadius: "5px",
        },
        header: {
            display: "flex",
            justifyContent: "center",
            fontFamily: "Khula",
            fontWeight: 300,
            fontSize: 45
        },
        row: {
            display: "flex",
            flexDirection: "row",
            width: rowWidth,
            maxheight: rowHeight,
            flexWrap: "wrap",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "5px",
        },
        placeHolder: {
            width: "100%",
            heght: "auto"
        },
        removeVideoPlayer: {
            display: "none"
        },
        videoWrapperShift: {
            backgroundColor: "black",
            width: "100%",
            height: "100%",
        },
        videoWrapperBeforeClick: {
            width: rowHeight,
            height: rowHeight,
            display: "inline-block",
            overflow: "hidden",
            position: "relative",
            borderRadius: "5px 0px 0px 5px",

        },
        videoWrapper: {
            backgroundColor: "black",
            height: rowHeight * .94,
            width: rowWidth,
            position: "relative",
            display: "flex !important",
            overflow: "hidden",
            borderRadius: "5px 5px 0px 0px",

        },
        loadingContainer: {
            display: "flex",
            flexDirection: "column",
            flexwrap: "wrap",
            width: "100%",
            backgroundColor: "grey",
        },
        removeLoadingContainer: {
            display: "none",
        },
        errorMessage: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "black",

        },
        removeErrorMessage: {
            display: "none",
        },
        textBox: {
            width: videoTextWidth
        },
        textBoxExpanded: {
            width: rowWidth,
        },
        '@media (max-width: 840px)':
        {
            videoWrapperBeforeClick: {
                height: rowHeight - 1,
                width: rowWidth,
                borderRadius: "5px 5px 0px 0px",

            },
            textBox: {
                width: rowWidth,
            },
            header: {
                width: rowWidth,
            },
        },
        '@media (max-width: 765px)':
        {
            videoWrapper: {
                height: rowHeight - 2.2,
                width: rowWidth / 1.1,

            },
            videoWrapperBeforeClick: {
                height: rowHeight - 2.2,
                width: rowWidth / 1.1,

            },
            row: {
                width: rowWidth / 1.1,
            },
            textBox: {
                width: rowWidth / 1.1,
            },
            header: {
                width: rowWidth / 1.1,
                fontSize: 30
            },
            textBoxExpanded: {
                width: rowWidth / 1.1,
            }
        },
        '@media (max-width: 700px)':
        {
            videoWrapper: {
                width: rowWidth / 1.3,
            },
            videoWrapperBeforeClick: {
                width: rowWidth / 1.3,
            },
            row: {
                width: rowWidth / 1.3,
            },
            textBox: {
                width: rowWidth / 1.3,
            },
            header: {
                width: rowWidth / 1.3,
                fontSize: 20
            },
            textBoxExpanded: {
                width: rowWidth / 1.3,
            }
        },
        '@media (max-width: 593px)':
        {
            videoWrapper: {
                width: rowWidth / 1.7,
            },
            videoWrapperBeforeClick: {
                width: rowWidth / 1.7,
            },
            row: {
                width: rowWidth / 1.7,
            },
            textBox: {
                width: rowWidth / 1.7,
            },
            header: {
                width: rowWidth / 1.7,
                fontSize: 20
            },
            textBoxExpanded: {
                width: rowWidth / 1.7,
            }
        }
    });
    const classes = useStyles();

    const videoRef = useRef<HTMLInputElement>(null);
    const [showPlayIcon, setShowPlayIcon] = useState(true);
    const [expandVideo, setExpandVideo] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [videoSource, setVideoSource] = useState("");
    const [playVideo, setPlayVideo] = useState(false);
    const [wallpaper, setWallpaper] = useState(null);
    const [videoWidth, setVideoWidth] = useState("100%");
    const [videoHeight, setVideoHeight] = useState("100%");
    const [showLoadingMessage, setShowLoadingMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [logo, setLogo] = useState("Personal Video Message");
    const [contactObject, setContactObject] = useState({ message: "", phoneNumber: "" });

    useEffect(() => {
        const getVideoDocument = async () => {
            const document = await VideoModel.get(videoId);
            const videoObject = document.toJSON();
            const { logo, platform, accountId, wallpaper } = videoObject;
            let width = videoObject.videoWidth ?? "100%";
            let height = videoObject.videoHeight ?? "100%";
            if (width >= 590) {
                width = "100%";
                height = "100%";
            }
            console.log("width", width, "height", height);
            setVideoWidth(width);
            setVideoHeight(height);
            if (logo) setLogo(logo);

            setContactObject({
                message: videoObject.message,
                phoneNumber: videoObject.accountPhoneNumber
            });
            if (wallpaper) setWallpaper(wallpaper);
            if (videoObject.transcodingStatus === "PROCESSING") {
                if (null !== videoRef.current) {
                    videoRef.current.style.display = "none";
                }
                setShowPlayIcon(false);
                setShowLoadingMessage(true);
                setTimeout(() => getVideoDocument(), 5000);
                return;
            };
            if (videoObject.transcodingStatus === "ERROR") {
                videoRef.current?.remove();
                setShowErrorMessage(true);
                setShowPlayIcon(false);
                return;
            }
            if (null !== videoRef.current) {
                videoRef.current.style.display = "inline-block";
            }
            const videoLink = getLinkToVideo(videoId);
            setVideoSource(videoLink);
            setShowPlayIcon(true);
            setShowLoadingMessage(false);
            //send to sqs to mark-video-as-watched lambda
            const sqs = new AWS.SQS();
            const jsonStringBody = JSON.stringify({
                platform,
                videoId,
                accountId,
                data: {
                    status: 'VIEWED'
                }
            });

            await sqs
                .sendMessage({
                    QueueUrl: SQS_URL,
                    MessageBody: jsonStringBody
                })
                .promise();
        };
        getVideoDocument();
    }, [videoId]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 840) {
                setExpandVideo(true);
            } else {
                setExpandVideo(false);
            }
        }

        window.addEventListener('resize', handleResize);
    });
    const handlePlayVideo = () => {
        setPlayVideo(true);
        setShowPlayIcon(false);
    };
    return (
        <div style={wallpaper ? {
            backgroundImage: `url(${wallpaper})`
        } : { backgroundColor: "#f4f5f7" }}>
            <div className={classes.column}>
                <div className={classes.header}>
                    <span >{logo}</span>
                </div>
                <div className={classes.row} style={{ background: "white" }}>

                    {videoWidth !== "100%" && (
                        <div ref={videoRef} className={playVideo ? classes.videoWrapper : classes.videoWrapperBeforeClick} style={{ width: videoWidth, height: videoHeight }} onClick={() => { handlePlayVideo(); }}>
                            <div className={(showPlayIcon) ? classes.displayIcon : classes.removeIcon}><FontAwesomeIcon icon={faPlay} color="white" size="2x"></FontAwesomeIcon></div>
                            <div className={playVideo ? classes.placeHolder : classes.videoWrapperShift} style={{ width: videoWidth, height: videoHeight }}>
                                <ReactPlayer
                                    id="videoPlayer"
                                    url={videoSource}
                                    width={videoWidth}
                                    height={videoHeight}
                                    playing={playVideo}
                                    controls={showControls}
                                    onEnded={() => { setShowControls(true); }}
                                />
                            </div>
                        </div>
                    )}

                    {videoWidth === "100%" && (
                        <div ref={videoRef} className={playVideo ? classes.videoWrapper : classes.videoWrapperBeforeClick} onClick={() => { handlePlayVideo(); }}>
                            <div className={(showPlayIcon) ? classes.displayIcon : classes.removeIcon}><FontAwesomeIcon icon={faPlay} color="white" size="2x"></FontAwesomeIcon></div>
                            <div className={playVideo ? classes.placeHolder : classes.videoWrapperShift}>
                                <ReactPlayer
                                    id="videoPlayer"
                                    url={videoSource}
                                    width={"100%"}
                                    height={"100%"}
                                    playing={playVideo}
                                    controls={showControls}
                                    onEnded={() => { setShowControls(true); }}
                                />
                            </div>
                        </div>
                    )}

                    <div className={(showErrorMessage) ? classes.videoWrapperBeforeClick : classes.removeErrorMessage}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "grey", fontSize: "140px", margin: "auto", marginTop: "6vh" }}></FontAwesomeIcon>
                            <span style={{ fontSize: "18px", color: "grey", margin: "auto", fontFamily: "Arial, sans-serif", marginTop: "14vh" }}>Error in Loading Media: File Could Not Be Played</span>
                        </div>
                    </div>
                    <div className={(showLoadingMessage) ? classes.videoWrapperBeforeClick : classes.removeLoadingContainer}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <FontAwesomeIcon icon={faSpinner} spin size="10x" style={{ marginTop: "8vh" }} />
                            <span style={{ marginTop: "9vh", fontSize: "20px", color: "gray", fontFamily: "Arial, sans-serif" }}>Video is loading...</span>
                        </div>
                    </div>
                    <div className={playVideo ? classes.textBoxExpanded : classes.textBox} >
                        <VideoText {...contactObject} expandVideo={expandVideo} />
                    </div>
                </div>
            </div>
        </div >
    );
};